var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Name:")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Description:")]),_c('b-input',{attrs:{"placeholder":"Description","maxlength":"200","type":"textarea"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),(_vm.type !== 'banner')?_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Date:")]),_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"placeholder":"Date"},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Periods:")]),_c('ValidationProvider',{attrs:{"name":"Periods","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"placeholder":"Periods","range":""},model:{value:(_vm.formData.periods),callback:function ($$v) {_vm.$set(_vm.formData, "periods", $$v)},expression:"formData.periods"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Action URL:")]),_c('ValidationProvider',{attrs:{"name":"Action URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"Action URL"},model:{value:(_vm.formData.actionurl),callback:function ($$v) {_vm.$set(_vm.formData, "actionurl", $$v)},expression:"formData.actionurl"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('b-field',[_c('b-switch',{model:{value:(_vm.formData.isenabled),callback:function ($$v) {_vm.$set(_vm.formData, "isenabled", $$v)},expression:"formData.isenabled"}},[_vm._v(" "+_vm._s(_vm.formData.isenabled ? "Enabled" : "Disabled")+" ")])],1)],1)]),_c('div',{class:["column"]},[(_vm.isEdit)?_c('div',[(!_vm.reupload)?[_c('h5',{staticClass:"label"},[_vm._v("Uploaded Image")]),_c('div',{class:_vm.$style.imageContainer},[_c('img',{attrs:{"src":_vm.formData.imageurl,"alt":"Current Image"}})]),_c('button',{staticClass:"mt-2",attrs:{"type":"button"},on:{"click":function () { return (_vm.reupload = true); }}},[_c('img',{attrs:{"src":require("@/assets/icons/Recycle-Bin.svg"),"alt":"Delete"}})])]:[_c('h5',{class:_vm.$style.generalTitle},[_vm._v("Image (5MB max)")]),_c('ValidationProvider',{ref:"imageUploader",attrs:{"name":"Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Upload',{class:_vm.$style.uploadContainer,attrs:{"fileTypes":_vm.imageFileTypes,"previousSelectedFile":_vm.formData.imageurl},on:{"input":_vm.onImageUpload}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]],2):_c('div',[_c('h5',{class:_vm.$style.generalTitle},[_vm._v("Image (5MB max)")]),_c('ValidationProvider',{ref:"imageUploader",attrs:{"name":"Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Upload',{class:_vm.$style.uploadContainer,attrs:{"fileTypes":_vm.imageFileTypes,"previousSelectedFile":_vm.formData.imageurl},on:{"input":_vm.onImageUpload}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{class:_vm.$style.buttonContainer},[_c('input',{attrs:{"type":"submit","disabled":invalid},domProps:{"value":_vm.isEdit ? 'Update' : 'Add'}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }