

















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import moment from 'moment';
import Upload from '@/components/common/Upload.vue';
import { CustomEventResponse, CreateCustomEventFormPayload } from '@/models/custom-event';
import { AcceptedImageFileType } from '@/utils/constants';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
import { ValidationObserver } from 'vee-validate';
import { uploadAttachment } from '@/utils/attachments';

@Component({
  components: {
    Dropdown,
    Upload,
    ValidationObserver,
  },
})
export default class CustomEventForm extends Vue {
  @Prop()
  type!: 'event' | 'banner';

  @Prop({ default: false })
  isEdit!: boolean;

  @Prop({ default: '' })
  defaultValues!: CustomEventResponse['data'];

  formData: CreateCustomEventFormPayload = {
    name: '',
    description: '',
    date: undefined,
    imageurl: '',
    actionurl: '',
    periods: [],
    isenabled: false,
  };

  reupload = false;

  get imageFileTypes() {
    return AcceptedImageFileType;
  }

  mounted() {
    if (this.isEdit) {
      const periods = [];

      if (this.defaultValues.periodfrom) periods.push(new Date(this.defaultValues.periodfrom));
      if (this.defaultValues.periodto) periods.push(new Date(this.defaultValues.periodto));

      this.formData = {
        ...this.defaultValues,
        date: new Date(this.defaultValues.date),
        periods: periods,
      };
    }
  }

  async onImageUpload(payload: UploadAttachmentWithThumbnail) {
    const { valid } = await (this.$refs?.imageUploader as Vue & {
      validate: (o: any) => Promise<{ valid: boolean }>;
    })?.validate(payload);

    const currentTimestamp = "_" + new Date().valueOf(); // to make unique file name
    if (valid) {
      const fileUrl = await uploadAttachment(
        payload.originalFile as File,
        this.formData.name.trim() + currentTimestamp,
        'background'
      );

      this.formData.imageurl = fileUrl;
    }
  }

  onSubmit() {
    const { periods, ...formData } = this.formData;

    this.$emit('submit', {
      ...formData,
      date: moment(formData.date).format('YYYY-MM-DD'),
      periodfrom: moment(periods[0]).format('YYYY-MM-DD HH:mm:ss'),
      periodto: moment(periods[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  }
}
